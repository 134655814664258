<template>
  <div>
    <div
      v-if="
        !$store.state.AppActiveUser.identityDocument ||
          !$store.state.AppActiveUser.identityDocument.front ||
          !$store.state.AppActiveUser.identityDocument.back
      "
    >
      <div style="display: flex;">
        <h6>Identity Document</h6>
        <vx-tooltip
          position="bottom"
          text="Please upload Driving licence or Passport or Identity card. Scans of both the front and back are usually required for government-issued IDs and driver's licenses. A passport scan is always acceptable and preferred. Files need to be JPEGs or PNGs smaller than 10MB. We cannot verify PDFs. Files should be rotated with the image right-side up, and have all information clearly legible."
        >
          <feather-icon
            style="cursor: pointer;"
            icon="InfoIcon"
            svgClasses="h-4 w-4"
            class="ml-1"
          />
        </vx-tooltip>
      </div>

      <vs-row
        vs-align="flex-start"
        vs-type="flex"
        vs-justify="left"
        vs-w="12"
        class="my-5"
      >
        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div
            style="width: 93%;"
            :class="{ dateClass: !errors.has('identityDocumentFront') }"
          >
            <label class="vs-input--label custom-label">Front</label>
            <div style="margin-top:3px;">
              <div class="mb-4" v-if="bankDetails.identityDocument.front">
                <div
                  v-if="
                    determineFileType(bankDetails.identityDocument.front) ===
                      'pdf'
                  "
                  style="margin:auto;width:50%;"
                >
                  <a :href="bankDetails.identityDocument.front" target="_blank"
                    >Click here to view file</a
                  >
                </div>
                <div v-else style="margin:auto;" class="img-wrapper">
                  <!-- <vs-avatar
                      :src="bankDetails.identityDocument.front"
                      size="130px"
                      align="center"
                    />-->
                  <img
                    :src="bankDetails.identityDocument.front"
                    class="img-fluid"
                    alt
                  />
                </div>
              </div>
              <div
                id="identityDocumentFrontUpload"
                :class="{ 'mt-3': bankDetails.identityDocument.front }"
                style="text-align:center;position:relative;"
                class="vs-con-loading__container"
              >
                <label
                  id="identityDocumentFrontId"
                  :class="{
                    'six-pixel': errors.has('identityDocumentFront')
                  }"
                  class="image-upload front-document-class vs-con-loading__container"
                  style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                >
                  + Attach Document Front
                  <input
                    type="file"
                    accept="image/*"
                    @change="
                      uploadImage(
                        $event,
                        'identityDocumentFrontUpload',
                        'front-document-class'
                      )
                    "
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("identityDocumentFront") }}
            </span>
          </div>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
          <div
            style="width: 98%;"
            :class="{ dateClass: !errors.has('identityDocumentBack') }"
          >
            <label class="vs-input--label custom-label">Back</label>
            <div style="margin-top:3px;">
              <div class="mb-4" v-if="bankDetails.identityDocument.back">
                <div
                  v-if="
                    determineFileType(bankDetails.identityDocument.back) ===
                      'pdf'
                  "
                  style="margin:auto;width:50%;"
                >
                  <a :href="bankDetails.identityDocument.back" target="_blank"
                    >Click here to view file</a
                  >
                </div>
                <div v-else style="margin:auto;" class="img-wrapper">
                  <!-- <vs-avatar
                      :src="bankDetails.identityDocument.back"
                      size="130px"
                      align="center"
                    />-->
                  <img
                    :src="bankDetails.identityDocument.back"
                    class="img-fluid"
                    alt
                  />
                </div>
              </div>
              <div
                id="identityDocumentBackUpload"
                :class="{ 'mt-3': bankDetails.identityDocument.back }"
                style="text-align:center;position:relative;"
                class="vs-con-loading__container"
              >
                <label
                  id="identityDocumentBackId"
                  :class="{ 'six-pixel': errors.has('identityDocumentBack') }"
                  class="image-upload back-document-class vs-con-loading__container"
                  style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                >
                  + Attach Document Back
                  <input
                    type="file"
                    accept="image/*"
                    @change="
                      uploadImage(
                        $event,
                        'identityDocumentBackUpload',
                        'back-document-class'
                      )
                    "
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
            <span class="text-danger text-sm">
              {{ errors.first("identityDocumentBack") }}
            </span>
          </div>
        </vs-col>
      </vs-row>

      <!-- <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12" class="m-1">
          <div style="width: 49%;" :class="{ dateClass: !errors.has('identityDocumentBack') }">
            <label class="vs-input--label custom-label">Back</label>
            <div style="margin-top:3px;">
              <div class="mb-4" v-if="bankDetails.identityDocument.back">
                <div
                  v-if="determineFileType(bankDetails.identityDocument.back) === 'pdf'"
                  style="margin:auto;width:50%;"
                >
                  <a
                    :href="bankDetails.identityDocument.back"
                    target="_blank"
                  >Click here to view file</a>
                </div>
                <div v-else style="margin:auto;height:auto;width:70%;">
                  <img :src="bankDetails.identityDocument.back" width="100%" height="100%" />
                </div>
              </div>
              <div
                id="identityDocumentBackUpload"
                :class="{ 'mt-3': bankDetails.identityDocument.back }"
                style="text-align:center;position:relative;"
                class="vs-con-loading__container"
              >
                <label
                  id="identityDocumentBackId"
                  :class="{ 'six-pixel': errors.has('identityDocumentBack') }"
                  class="image-upload"
                  style="border:1px solid #cccccc; display:inline-block;width:100%; border-radius: 5px; padding:10px;"
                >
                  + Attach Document Back
                  <input
                    type="file"
                    accept="image/*"
                    @change="uploadImage($event, 'identityDocumentBackUpload')"
                    id="file-input"
                    hidden
                  />
                </label>
              </div>
            </div>
            <span class="text-danger text-sm">{{ errors.first("identityDocumentBack") }}</span>
          </div>
        </vs-row>-->

      <vs-divider />
    </div>

    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left" vs-w="12">
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-1 mr-5">
          <vs-input
            :danger="errors.first('accountHolderName') ? true : false"
            :success="
              !errors.first('accountHolderName') &&
                bankDetails.accountHolderName != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="Account Holder Name"
            label-placeholder="Account Holder Name"
            name="accountHolderName"
            placeholder="Account Holder Name"
            v-model="bankDetails.accountHolderName"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("accountHolderName")
          }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-1">
          <vs-input
            :danger="errors.first('bsb') ? true : false"
            :success="!errors.first('bsb') && bankDetails.bsb != ''"
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required|digits:6'"
            data-vv-validate-on="blur"
            data-vv-as="BSB"
            label-placeholder="BSB"
            name="bsb"
            placeholder="BSB"
            v-model="bankDetails.bsb"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{ errors.first("bsb") }}</span>
        </div>
      </vs-col>

      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-1 mr-5">
          <vs-input
            :danger="errors.first('accountNumber') ? true : false"
            :success="
              !errors.first('accountNumber') && bankDetails.accountNumber != ''
            "
            val-icon-success="done"
            val-icon-danger="error"
            v-validate="'required'"
            data-vv-validate-on="blur"
            data-vv-as="Account Number"
            label-placeholder="Account Number"
            name="accountNumber"
            placeholder="Account Number"
            v-model="bankDetails.accountNumber"
            class="w-full mt-6"
          />
          <span class="text-danger text-sm">{{
            errors.first("accountNumber")
          }}</span>
        </div>
      </vs-col>
    </vs-row>

    <vs-row>
      <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
        <div class="w-full m-1 mt-5">
          <vs-button @click="saveBankDetails" :disabled="!validateForm"
            >Save Details</vs-button
          >
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";
import axios from "@/axios";

export default {
  data() {
    return {
      bankDetails: {
        accountHolderName: "",
        bsb: "",
        accountNumber: "",
        identityDocument: {
          front: "",
          back: ""
        }
      }
    };
  },
  methods: {
    ...mapActions("doctor", ["saveDoctorBankDetails"]),
    determineFileType(str) {
      var res = str.substring(str.length - 3, str.length);
      return res;
    },
    uploadImage(event, key = "", loadingClass = "") {
      this.$vs.loading();
      //   this.$vs.loading({
      //     background: "#fff",
      //     color: "#626262",
      //     container: `.${loadingClass}`,
      //     scale: 0.45
      //   });

      let self = this;
      let data = new FormData();
      data.append("profileImage", event.target.files[0]);
      let config = {
        header: {
          "Content-Type": "image/png"
        }
      };
      axios
        .post("/api/v1/doctors/upload?key=" + key, data, config)
        .then(res => {
          if (key === "identityDocumentFrontUpload") {
            this.errors.remove("identityDocumentFront");
            document.getElementById(
              "identityDocumentFrontId"
            ).style.borderColor = "#cccccc";
            self.bankDetails.identityDocument.front = res.data.Location;
          }

          if (key === "identityDocumentBackUpload") {
            this.errors.remove("identityDocumentBack");
            document.getElementById(
              "identityDocumentBackId"
            ).style.borderColor = "#cccccc";
            self.bankDetails.identityDocument.back = res.data.Location;
          }
          //   this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          this.$vs.loading.close();
        })
        .catch(err => {
          //   this.$vs.loading.close(`.${loadingClass} > .con-vs-loading`);
          this.$vs.loading.close();
          if (err && err.status === 415) {
            this.$vs.notify({
              title: "Upload Failed",
              text: "File Format Not Supported",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          } else {
            this.$vs.notify({
              title: "Upload Failed",
              text: "Please try again later.",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger"
            });
          }
        });
    },
    async saveBankDetails() {
      const self = this;
      let isValid = await self.$validator.validate();

      if (
        !this.$store.state.AppActiveUser.identityDocument ||
        !this.$store.state.AppActiveUser.identityDocument.front ||
        !this.$store.state.AppActiveUser.identityDocument.back
      ) {
        if (this.bankDetails.identityDocument.front === "") {
          this.errors.add({
            field: "identityDocumentFront",
            msg: "Identity document front is required."
          });

          document.getElementById("identityDocumentFrontId").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("identityDocumentFrontId").style.borderColor =
            "#cccccc";
          this.errors.remove("identityDocumentFront");
        }

        if (this.bankDetails.identityDocument.back === "") {
          this.errors.add({
            field: "identityDocumentBack",
            msg: "Identity document back is required."
          });
          document.getElementById("identityDocumentBackId").style.borderColor =
            "#ea5455";
          isValid = false;
        } else {
          document.getElementById("identityDocumentBackId").style.borderColor =
            "#cccccc";
          this.errors.remove("identityDocumentBack");
        }
      } else {
        delete self.bankDetails.identityDocument;
      }

      if (isValid) {
        self.$vs.loading();
        await self
          .saveDoctorBankDetails(self.bankDetails)
          .then(res => {
            self.$vs.notify({
              title: "Success",
              text: "Bank details saved successfully !",
              color: "success"
            });
            self.$vs.loading.close();
            self.$router.push("/doctor/dashboard");
          })
          .catch(err => {
            let errMessage = err.data.message;

            if (
              err.data.message ===
              "Invalid routing number for AU. The number must contain both the bank code and the branch code, and should be in the format xxxxxx."
            )
              errMessage =
                "Invalid BSB for AU. The number must contain both the bank code and the branch code, and should be in the format xxxxxx.";
            self.$vs.notify({
              title: "Failed",
              text: errMessage,
              color: "danger"
            });
            self.$vs.loading.close();
          });
      }
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    }
  }
};
</script>
